import React from 'react';
import DocsTitles from '../components/sections/DocsTitles';


const Documents = () => {
  
    return (
      <React.Fragment>
        <DocsTitles className="illustration-section-05"/>
        <div style={{height: '100px'}}/>
      </React.Fragment>
    );
}

export default Documents;