import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { Link, useParams, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { getDocsList } from '../../utils/api';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const DocsTitles = ({
  className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...rest
}) => {
    const params = useParams()
    const [docs, setDocs] = useState(null)
  
    useEffect (() => {
       getDocsList()
        .then((res) => {
          setDocs(res)
        })
        .catch()
    },[])


    if(!docs) {
      return <></>
    }

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap illustration-section-06',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Раскрытие информации',
      paragraph: ''
    };

    const list = docs ? docs : []
    const secondaryList = params.id ? list.find(el => el.link === params.id)?.list : []
    const getType = (link) => {
      const arr = link.split('.')
      return arr[arr.length-1]
    }

    // reveal-scale-up
    return (
      <section
        {...rest}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            {params.id && <Link to="/documents">Назад</Link>}
            <div className={tilesClasses}>
            {
              !params.id ? 
                <>
                   {
                      list.map((el) => (
                        <Link to={`/documents/${el.link}`} className="tiles-item" style={{ cursor: 'pointer', textDecoration: 'none'}}>
                          <div className="tiles-item-inner has-shadow">
                            <div className="testimonial-item-header">
                              <div className="testimonial-item-image">
                                <Image
                                  src={require(`./../../assets/icons/${el.image}.svg`)}
                                  alt="Testimonial 01"
                                  width={26}
                                  height={26} />
                              </div>
                              <div style={{fontSize: 14, fontWeight: 600, lineHeight: '16px', color: "#1F252D"}}>{el.title}</div>
                              <div style={{fontSize: 13, color: '#C1C9C9', lineHeight: '14px', marginTop: 10}}>{el.desciption}</div>
                            </div>
                          </div>
                        </Link>
                      ))
                     
                }
                </>
              : <>        
                  {
                    secondaryList.length ?
                    secondaryList.map((el) => (
                      <Link 
                        className="tiles-item"
                        style={{ cursor: 'pointer', textDecoration: 'none'}}
                        to={`/api/docs/${el.link}`} target="_blank"
                        download
                        >
                        <div className="tiles-item-inner has-shadow">
                          <div className="testimonial-item-header">
                            <div className="testimonial-item-image">
                              <Image
                                  src={require(`./../../assets/icons/docs.svg`)}
                                  alt="Testimonial 01"
                                  width={26}
                                  height={26}
                                />
                              <div style={{
                                display: 'flex',
                                  position: 'absolute',
                                  bottom: '20px',
                                  left: '15px',
                                  fontSize: '12px',
                                  color: '#00AB9C',
                                  background: '#fff',
                                  alignItems: 'flex-start',
                                  width: '20px',
                                  maxHeight:'15px',
                                  textAlign: 'center',
                                  boxSizing: 'border-box'
                                }}>
                                  {getType(el.link)}
                              </div>
                            </div>
                            <div style={{fontSize: 14, fontWeight: 600, lineHeight: '16px', color: "#1F252D"}}>{el.title}</div>
                            <div style={{fontSize: 13, color: '#C1C9C9', lineHeight: '14px', marginTop: 10}}>{el.desciption}</div>
                          </div>
                        </div>
                      </Link>
                    ))
                    :
                    <div style={{display: 'flex', width: '100%', alignItems: 'center', marginTop: '50px', justifyContent: 'center'}}>
                        В данный момент ни одного документа не опубликовано
                    </div>
                  }
              </>
            }
           
            </div>
          </div>
        </div>
      </section>
    );
}

DocsTitles.propTypes = propTypes;
DocsTitles.defaultProps = defaultProps;
    
export default withRouter(DocsTitles);