import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const LoginForm = ({
  className,
  topOuterDivider,
  bottomOuterDivider,      
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');


    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Вход для вдминистратора',
    };

    const auth  = () => {
    /*   authService({email, password})
      .then(res => console.log('res', res))
      .catch((e) => console.log('e', e)) */
    }

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div>
                    <fieldset>
                      <div className="mb-12">
                        <input
                          // type="email"
                          label="Email"
                          style={{ width: '100%', height: '50px', padding: 15}}
                          placeholder="Email"
                          labelHidden
                          onChange={e => setEmail(e.target.value)}
                          required />
                      </div>
                      <div className="mb-12">
                        <input
                          type="password"
                          style={{ width: '100%', height: '50px', padding: 15}}
                          label="Password"
                          placeholder="Password"
                          onChange={e => setPassword(e.target.value)}
                          labelHidden
                          required />
                      </div>
                      <div className="mt-24 mb-32">
                        <Button color="primary" wide onClick={auth}>Войти</Button>
                      </div>
                     
                    </fieldset>
                  </div>
                  <div className="signin-bottom has-top-divider">
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;