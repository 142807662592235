import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3'])
}

const defaultProps = {
  children: null,
  tag: 'h2'
}

class SectionHeaderLeft extends React.Component {

  render() {

    const {
      className,
      data,
      children,
      tag,
      ...props
    } = this.props;

    const classes = classNames(
      'section-header',
      className
    );    

    const bill = data.find((el) => el.type === 'bill')

    const deal_type = data.find((el) => el.type === 'deal_type')

    return (
      <React.Fragment>
          <div
            {...props}
            className={classes}
          >
            <div className="container-xs" style={{ minWidth: '80%', paddingBottom: 0}}
>             
              {bill.data &&
              <div>
                {bill.data.map((el) => (
                  <p className="m-0" style={{ textAlign: 'left', fontSize: 16}}>
                    <strong style={{fontWeight: 600}}>{`${el.title}: `}</strong>
                    {el.description}
                    </p>
                ))}
              </div>
               
              }
            </div>
            <div className="container-xs" style={{ minWidth: '80%', textAlign: 'left'}}>
              {
                deal_type.lists.map((el) => (
                  <>
                  <h5>{el.title}</h5>
                  <div style={{fontSize: '14px', lineHeight: '18px', textAlign: 'left',}}>
                    {el.list.map((l, i) => <p>{l}</p>)}
                  </div>
                  </>
                ))
              }
            </div>
          </div>
      </React.Fragment>
    );
  }
}

SectionHeaderLeft.propTypes = propTypes;
SectionHeaderLeft.defaultProps = defaultProps;

export default SectionHeaderLeft;