import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
  pricingSlider: PropTypes.bool
}

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
  pricingSlider: false
}

class Accounts extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSwitcher,
      pricingSlider,
      data,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'pricing section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'pricing-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap illustration-section-07',
      pushLeft && 'push-left'
    );

    const current = data.find((el) => el.type === 'bill_bank')

    const sectionHeader = {
      title: current.title,
      paragraph: ''
    };


    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content invert-color" />
            <div className={tilesClasses}>
              <div className="tiles-item" style={{ minWidth: '60%'}}>
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-features mb-40">
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                      {current.data.map((el) => (
                          <li className="m-0">
                            <strong style={{fontWeight: 600}}>{`${el.title}: `}</strong>
                            {el.description}
                            </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>                

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Accounts.propTypes = propTypes;
Accounts.defaultProps = defaultProps;

export default Accounts;