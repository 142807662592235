import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer',
      topOuterDivider && 'has-top-divider',
      className
    );

    return (
      <footer
        {...props}
        className={classes}
        style={{maxHeight: '120px'}}
      >
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              topDivider && 'has-top-divider'
            )}>
            <div className="footer-top text-xxs">
              <div className="footer-blocks">
                <div style={{display: 'flex', width: "100%", direction:"row", justifyContent: 'space-between'}}>
                  <Link to="/" style={{fontSize:22, fontWeight: 600, borderLeft: '2px solid #fff', borderBottom: '2px solid #fff', color: '#00AB9C', paddingLeft: 5, textDecoration: 'none' }}>
                    УК "НАДЕЖНЫЙ ПАРТНЕР"
                  </Link>
                  <div className="footer-copyright">&copy; 2024 УК "Надежный партнер"</div>
                </div>
              </div>
            </div>
            <div className="footer-bottom space-between center-content-mobile text-xxs reveal-from-bottom" data-reveal-offset="0">
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;