import React, { useEffect, useState } from 'react';
import getTranparency, { getDocsList, updateSiteInfo, uploadFilres, updateDocList } from '../utils/api';
import ReactModal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    height: '600px',
    width: '70%',
    transform: 'translate(-50%, -50%)',
  },
};

function transliterate(cyrillicString) {
  const cyrillicCharacters = 'абвгдеёжзийклмнопрстуфхцчшщъыьэюяАБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ';
  const latinCharacters = 'abvgdeejzijklmnoprstufhzcss_y_euaABVGDEEJZIJKLMNOPRSTUFHZCSS_Y_EUA';
  let transliteratedString = '';

  for (let i = 0; i < cyrillicString.length; i++) {
    const charIndex = cyrillicCharacters.indexOf(cyrillicString[i]);
    if (charIndex !== -1) {
      transliteratedString += latinCharacters[charIndex];
    } else {
      transliteratedString += cyrillicString[i];
    }
  }

  return transliteratedString;
}

const  Maintainer = () => {
  const [lock, setLock] = useState(localStorage.getItem('a') === 'Hezx@09!!dsa' || false )
  const [file, setFile] = useState({});
  const [isOpen, setIsOpen] = useState(false)
  const [editObject, setEditObject] = useState({})
  const [isNewOpen, setIsNewOpen] = useState(false)
  const [newFile, setNewFile] = useState({})
  const [uploadLoading, setUploadLoading] = useState(false)

  const [currentPage, setCurrentPage] = useState('docs')
  const [inp, setInp] = useState(null)
  const [data, setData] = useState(null)
  const [docs, setDocs] = useState(null)
  
  useEffect(() => {
    getTranparency()
      .then((res) => {
        setData(res.main)
      })
      .catch()
    getDocsList()
    .then((res) => {
      setDocs(res)
    })
    .catch()
  },[])

  const handleAddFile = (key, value) => {
    setNewFile((prev) => ({...prev, [key]: value}))
  }

  const handleEditFile = (key, value) => {
    setEditObject((prev) => ({...prev, [key]: value}))
  }

  const openModal = (index, suIndex, eli) => {
    setIsOpen({index, suIndex})
    setEditObject(eli)
  } 

  const send = () => {
    updateSiteInfo(data, 'Hezx@09!!dsa')
    .then(() => {
      window.location.href = '/'
    })
    .catch(e => console.log('e', e))
  }

  const slowHandle = (index, key, value) => {
    const now = [...data];
    now[index][key] = value
    setData(now)
  }

  const slowHandleDocs = (index, key, value) => {
    const now = [...docs];
    now[index][key] = value
    setDocs(now)
  }

  const arrayHandle = (index, key, i, array, value, name = 'data') => {
    const now = [...data];
    const newArraye = [...array];
    newArraye[i][key] = value
    now[index][name] = [...newArraye]
    setData(now)
  }

  const incArrayHandler = (index, mainindex, currindex, value) => {
    const now = [...data];
    now[index].lists[mainindex].list[currindex] = value
    setData(now)
  }

  const addNewLine =(index, mainindex) => {
    const now = [...data];
    now[index].lists[mainindex].list.push('')
    setData(now)
  }

  const goNext = () => {
    if(inp === 'Hezx@09!!dsa') {
      localStorage.setItem('a', inp)
      setLock(inp === 'Hezx@09!!dsa')
    }
  }

  const goOut = () => {
    localStorage.removeItem('a')
    setLock(false);
    setInp('')
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    setUploadLoading(true)
    const fileName = `${Date.now()}_${transliterate(file?.name || '').replaceAll(' ', '_')}`
    uploadFilres(file, fileName, 'Hezx@09!!dsa')
    .then(_ => handleAddFile('link', fileName))
    .catch(e => console.log('e', e))
    .finally(() => setUploadLoading(false))
  };

  const saveNewFileHandler = () => {
    const nD = [...docs]
    nD[isNewOpen].list.push(newFile)
    setUploadLoading(true)
    updateDocList(nD, 'Hezx@09!!dsa')
    .then((res) => {
      setIsNewOpen(false);
      setNewFile({})
      getDocsList()
        .then((res) => {
          setDocs(res)
        })
        .catch()
    })
    .catch(res => console.log('ERR', res))
    .finally(setUploadLoading(false))
  }

  const saveEdit = () => {
    const nD = [...docs]
    nD[isOpen?.index].list[isOpen?.suIndex] = {...editObject}
    updateDocList(nD, 'Hezx@09!!dsa')
    .then((res) => {
      setIsOpen(false)
            
      getDocsList()
        .then((res) => {
          setDocs(res)
        })
        .catch()
    })
    .catch(res => console.log('ERR', res))
  }

  const removFile = (index, fileName) => {
    const nD = [...docs]
    nD[index].list = nD[index].list.filter((el) => el.link !== fileName)
    updateDocList(nD, 'Hezx@09!!dsa')
    .then((res) => getDocsList()
        .then((res) => {
          setDocs(res)
        })
        .catch())
    .catch(res => console.log('ERR', res))
  }

  if(!data || !docs) return <></>

  if(!lock) return (
  <div style={{marginTop: 140}} className='container'>
    <div style={{ width: '420px', margin: '0 auto', display: 'flex', flexDirection: "column", alignItems: "center"}} className='inputsBox'>
      <div className='inputBlock' style={{width: '100%'}}>
            <input value={inp} onChange={(e) => setInp(e.target.value)}/>
          </div>
          <button className='button_send' onClick={goNext}>Продолжить</button>
      </div>
    </div>
  ) 
  
  if(currentPage === 'main') return (
    <div style={{marginTop: 100}} className='container'>
      <div className='inputsBox' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', flexDirection: 'row', gap: '30px'}}>
          <div onClick={() => setCurrentPage('main')}>Главная</div>
          <div onClick={() => setCurrentPage('docs')}>Документы</div>
        </div>
        
        <div onClick={goOut} style={{cursor: 'pointer'}}>Отключиться</div>
      </div>
      <div className='inputsBox'>
        <h3>{`Имя блока: ${data[0].name}`}</h3>
        <div className='inputBlock'>
          <span>Заголовок</span>
          <input value={data[0].title} onChange={(e) => slowHandle(0,'title',e.target.value )}/>
        </div>
        <div className='inputBlock'>
          <span>Текст</span>
          <textarea value={data[0].description} onChange={(e) => slowHandle(0,'description',e.target.value )}/>
        </div>
      </div>

      <div className='inputsBox'>
        <h3>{`Имя блока: ${data[1].name}`}</h3>
        <div className='inputBlock'>
          <span>Заголовок</span>
          <input value={data[1].title} onChange={(e) => slowHandle(1,'title',e.target.value )}/>
        </div>
        {data[1].data.map((el, index, array) => (
          <div style={{display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
            <div className='inputBlock' style={{width: '28%'}}>
            <span>Заголовок</span>
            <input value={el.title} onChange={(e) => arrayHandle(1, 'title', index, array, e.target.value)}/>
          </div>
          <div className='inputBlock' style={{width: '70%'}}>
            <span>Текст</span>
            <input value={el.description} onChange={(e) => arrayHandle(1, 'description', index, array, e.target.value)}/>
          </div>
          </div>
        ))}
      </div>

      <div className='inputsBox'>
        <h3>{`Имя блока: ${data[2].name}`}</h3>
        <div className='inputBlock'>
          {
            data[2].lists.map((el, mainindex, mainarray) => (
              <div>
                <div className='inputBlock'>
                  <span>Заголовок</span>
                  <input value={el.title} onChange={(e => arrayHandle(2, 'title', mainindex, mainarray, e.target.value, 'lists'))}/>
                </div>
                <span>Список</span>
                <div style={{display: "flex", flexDirection: 'column'}}>
                  {el.list.map((el2, currindex) => (
                    <input value={el2} style={{marginBottom: '20px'}} onChange={(e => incArrayHandler(2, mainindex, currindex, e.target.value))}/>
                  ))}
                  <div onClick={() => addNewLine(2, mainindex)}>+ Добавить</div>
                </div>
                <div style={{ borderBottom: '1px solid #959595', margin: '20px 0', }}>{''}</div>
              </div>
            ))
          }
          
        </div>
      </div>


      <div className='inputsBox'>
        <h3>{`Имя блока: ${data[3].name}`}</h3>
        <div className='inputBlock'>
          <span>Заголовок</span>
          <input value={data[3].title} onChange={(e) => slowHandle(3,'title',e.target.value )}/>
        </div>
        {data[3].data.map((el, index, array) => (
          <div style={{display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
            <div className='inputBlock' style={{width: '28%'}}>
            <span>Заголовок</span>
            <input value={el.title}  onChange={(e) => arrayHandle(3, 'title', index, array, e.target.value)}/>
          </div>
          <div className='inputBlock' style={{width: '70%'}}>
            <span>Текст</span>
            <input value={el.description}  onChange={(e) => arrayHandle(3, 'description', index, array, e.target.value)}/>
          </div>
          </div>
        ))}
      </div>

      <div className='inputsBox'>
        <h3>{`Имя блока: ${data[4].name}`}</h3>
        <div className='inputBlock'>
          <span>Заголовок</span>
          <input value={data[4].title} onChange={(e) => slowHandle(4,'title',e.target.value )}/>
        </div>
        {data[4].data.map((el, index, array) => (
          <div style={{display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
          <div className='inputBlock' style={{width: '28%'}}>
          <span>Заголовок</span>
          <input value={el.title}  onChange={(e) => arrayHandle(4, 'title', index, array, e.target.value)}/>
        </div>
        <div className='inputBlock' style={{width: '70%'}}>
          <span>Текст</span>
          <input value={el.description}  onChange={(e) => arrayHandle(4, 'description', index, array, e.target.value)}/>
        </div>
        </div>
        ))}
      </div>

      <div className='inputsBox'>
        <h3>{`Имя блока: ${data[5].name}`}</h3>
        <div className='inputBlock'>
          <span>Заголовок</span>
          <input value={data[5].title} onChange={(e) => slowHandle(5,'title',e.target.value )}/>
        </div>
        <div className='inputBlock'>
          <span>Текст</span>
          <textarea value={data[5].description} onChange={(e) => slowHandle(5,'description',e.target.value )}/>
        </div>
        <div className='inputBlock'>
          <span>Дополнительно</span>
          <textarea value={data[5].description_italic} onChange={(e) => slowHandle(5,'description_italic',e.target.value )}/>
        </div>
      </div>

          <button className='button_send' onClick={send}>СОХРАНИТЬ</button>
    </div>

  )

  if(currentPage === 'docs') return(
    <>
    <ReactModal
      isOpen={typeof isOpen?.index === 'number'}
      contentLabel="Example Modal"
      onAfterClose={() => setIsOpen(false)}
      style={customStyles}
    >
      <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
        <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            Редактирование документа
          </div>
          <div style={{display: 'flex', flexDirection: 'row', gap: '20px'}}>
            <button className='butt' onClick={() => saveEdit()}>Сохранить</button>
            <button onClick={() => {setIsOpen(false); setEditObject({})}}>Закрыть</button>
          </div>
        </div>
        <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
          <div className='inputBlock'>
            <span>Заголовок</span>
            <input value={editObject?.title} onChange={(e) => handleEditFile('title', e.target.value)}/>
          </div>
          <div className='inputBlock'>
            <span>Пояснение</span>
            <textarea value={editObject?.desciption} onChange={(e) => handleEditFile('desciption', e.target.value)}/>
          </div>
          <div className='inputBlock'>
            <span>Файл</span>
            <span>{editObject?.link}</span>
          </div>
        </div>
      </div>
    </ReactModal>
    <ReactModal
      isOpen={typeof isNewOpen === 'number'}
      contentLabel="Новый документ"
      onAfterClose={() => setIsOpen(false)}
      style={customStyles}
    >
      <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
        <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
          Новый документ
          </div>
          <div style={{display: 'flex', flexDirection: 'row', gap: '20px'}}>
            <button onClick={() => {setNewFile({}); setIsNewOpen(false)}}>Закрыть</button>
          </div>
        </div>
        <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
          <div className='inputBlock'>
            <span>Заголовок</span>
            <input value={newFile?.title} onChange={(e) => handleAddFile('title', e.target.value)}/>
          </div>
          <div className='inputBlock'>
            <span>Пояснение</span>
            <textarea value={newFile?.desciption} onChange={(e) => handleAddFile('desciption', e.target.value)}/>
          </div>
          <div className='inputBlock'>
            <span>Файл</span>
            {!newFile?.link?.length ? <input type="file" onChange={handleFileChange}/> : newFile.link}
            <button  disabled={uploadLoading} className='butt' style={{marginTop: '20px'}} onClick={!newFile?.link?.length ? handleFileUpload : saveNewFileHandler}>{!newFile?.link?.length ? 'Загрузить' : 'Сохранить'}</button>
          </div>
        </div>
      </div>
    </ReactModal>
    <div style={{marginTop: 100}} className='container'>
       <div className='inputsBox' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', flexDirection: 'row', gap: '30px'}}>
          <div onClick={() => setCurrentPage('main')}>Главная</div>
          <div>Документы</div>
        </div>
        
        <div onClick={goOut} style={{cursor: 'pointer'}}>Отключиться</div>
      </div>
      <div>
        {
          docs && docs?.map((el, index) => (
            <div className='inputsBox'>
              <div className='inputBlock'>
                <span>Заголовок</span>
                <input value={el.title} onChange={(e) => slowHandleDocs(0,'title',e.target.value )}/>
              </div>
              <div className='inputBlock'>
                <span>Пояснение</span>
                <input value={el.desciption} onChange={(e) => slowHandleDocs(0,'desciption',e.target.value )}/>
              </div>
                
                <div style={{display: 'flex', marginTop: '20px', width: '100%', flexDirection: 'column'}}>
                
                  {el.list.map((eli, ind2) => (
                    <div style={{display: 'flex', direction: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid grey', marginBottom: '20px'}}>
                        <div className='titlesDocs'>
                          <a href={`/docs/${eli.link}`} target='_blank' rel="noopener noreferrer" className='titlesDocs_title'>{eli.title}</a>
                          <div className='titlesDocs_desc'>{eli.desciption}</div>
                        </div>
                        <div className='titlesDocs_buttons'>
                          <button onClick={() => openModal(index, ind2, eli)}>Изменить</button>
                          <button onClick={() => removFile(index, eli.link)}>Удалить</button>
                        </div>
                    </div>
                  ))}
                </div>
                <div>
                  <button className='butt' onClick={() => setIsNewOpen(index)}>Загрузить</button>
                </div>
            </div>
          ))
        }
          <button className='button_send' >СОХРАНИТЬ</button>
      </div>
  </div>
  </>
  )
}

export default Maintainer
