import React, { useEffect, useState } from 'react';
import InfoBlocks from '../components/sections/InfoBlocks';
import Accounts from '../components/sections/Accounts';
import Cta from '../components/sections/Cta';
import SectionHeader from '../components/sections/partials/SectionHeader';
import getTranparency from '../utils/api'




const Home = () => {
  const [data, setData] = useState(null)
  
  useEffect(() => {
    getTranparency()
      .then((res) => {
        setData(res.main)
      })
      .catch()
  },[])

  if(!data) return <></>

  const current = data.find((el) => el.type === 'other')

  const sectionHeader = {
    title: current.title,
    paragraph: current.description
  };

    return (
      <React.Fragment>
        <InfoBlocks invertMobile imageFill className="illustration-section-01" data={data}/>
        <Accounts className="illustration-section-03" data={data}/>
        <Cta bottomDivider data={data}/>    
        <div style={{height: 50}}/>
        <div className="container">
        <SectionHeader data={sectionHeader} className="center-content">
          <div className='invert-color' style={{ textAlign: 'left', fontSize: 16, fontStyle: 'italic', paddingTop: '20px'}}>
            {current.description_italic}
          </div>
        </SectionHeader>
        </div>
        <div style={{height: '100px'}}/>
      </React.Fragment>
    );
}

export default Home;